import * as React from 'react';
import * as btnStyles from '../styles/button.module.css';
import MyFuncButton from './MyFuncButton';

const UpButton = () => {
  React.useEffect(() => {
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const myID = document.getElementById('go-up-btn');

    const myScrollFunc = function () {
      const y = window.pageYOffset;
      if (y >= 2.5 * vh) {
        myID.style.display = 'block';
      } else {
        myID.style.display = 'none';
      }
    };

    window.addEventListener('scroll', myScrollFunc);
  }, []);
  return (

    <MyFuncButton
      style={{ display: 'none' }}
      id="go-up-btn"
      type="button"
      className={btnStyles.upBtnContainer}
      title={(
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-arrow-up"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
            />
          </svg>
        </span>
        )}
      onClick={() => {
        document.getElementById('modules-container').scrollIntoView({ behavior: 'smooth' });
      }}
    />
  );
};

export default UpButton;
