/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import window from 'global';
import * as React from 'react';
import MyCarousel from '../components/MyCarousel';
import MyCarouselSlide from '../components/CarouselSlide';
import DivideModuleCard from '../components/DivideModuleCard';
import Footer from '../components/Footer';
import Head from '../components/Head';
import HoverHighlightText from '../components/HoverHighlightText';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import ModuleCard from '../components/ModuleCard';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import UpButton from '../components/UpButton';
import javaAdvanced from '../images/java-advanced.webp';
import javaBasics from '../images/java-basics.webp';
import javaGame from '../images/java-game-development.webp';
import javaBgImg from '../images/java-learn-coding.webp';
import javaMobile from '../images/java-mobile-development.webp';
import javaOverviewBgImg from '../images/java-overview.webp';
import javaProgramming from '../images/java-programming.webp';
import pythonAlg from '../images/python-algorithm-and-data-structure.webp';
import pythonBE from '../images/python-back-end-web.webp';
import pythonCourseBgImg from '../images/python-course-overview.webp';
import pythonFE from '../images/python-front-end-web.webp';
import pythonPrj from '../images/python-hands-on-project.webp';
import pythonWeb from '../images/python-web-development.webp';
import * as divideModuleCardStyles from '../styles/divide-module-card.module.css';
import { hideCarousel } from '../utils';

const JavaPage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Head pageTitle="Java Master" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <Section backgroundImage={javaBgImg} customStyle={{ textAlign: 'center' }}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="JAVA MASTER" style={{ fontSize: 'calc(3vw + 3vh)' }} />
            <br />
            <MainSubtitle text="ADVANCED SKILLS TO BUILD COMPLEX APPLICATION" style={{ fontSize: 'calc(1.2vw + 1.2vh)' }} />
            <br />
            {/* <MainTitle text="COMING SOON" /> */}
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="JAVA MASTER" style={{ fontSize: 'calc(4vw + 4vh)' }} />
            <br />
            <MainSubtitle text="ADVANCED SKILLS TO BUILD COMPLEX APPLICATION" style={{ fontSize: 'calc(2vw + 2vh)' }} />
            <br />
            {/* <MainTitle text="COMING SOON" style={{ fontSize: 'calc(4vw + 4vh)' }} /> */}
          </SectionContentContainer>
        )}
      </Section>
      {/* {dimension.width > breakpoint ? (
        <Section customStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5% 35%',
          }}
          >
            <ContactForm formTitle="INTERESTED? KEEP UPDATED HERE!" />
          </SectionContentContainer>
        </Section>
      ) : (
        <Section customStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5%',
          }}
          >
            <ContactForm formTitle="INTERESTED? KEEP UPDATED HERE!" />
          </SectionContentContainer>
        </Section>
      )} */}
      <Section backgroundImage={javaOverviewBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4" customStyle={{ position: 'relative', padding: '40vh 0' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ABOUT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about Python programming basics and advanced"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn to use Python to develop different applications, ranging from frontend development to backend
                development"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Discover how Python is used in data analytics as well as robotics programming through
                hands on projects at class"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ position: 'relative', padding: '20vh 0' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ABOUT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn to become an industry-ready Java developer with Java Basics and Advanced"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Creating your own website from scratch with Backend and Frontend courses using Java and others technologies"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about Mobile and Game development fundamentals and create a game plugin of your very own"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section id="modules-container" customStyle={{ backgroundColor: 'black' }}>
        {dimension.width > breakpoint ? (
          <>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', top: '20%', width: '100%',
            }}
            >
              <DivideModuleCard moduleName="JAVA PROGRAMMING" backgroundImage={javaProgramming}>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${javaBasics})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('javaBasics').style.display = 'block';
                    document.getElementById('javaBasics').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="JAVA BASICS" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${javaAdvanced})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('javaAdvanced').style.display = 'block';
                    document.getElementById('javaAdvanced').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="JAVA ADVANCED" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
              </DivideModuleCard>
              <DivideModuleCard moduleName="WEB DEVELOPMENT" backgroundImage={pythonWeb}>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${pythonFE})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('pythonFE').style.display = 'block';
                    document.getElementById('pythonFE').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="FRONT END" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${pythonBE})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('pythonBE').style.display = 'block';
                    document.getElementById('pythonBE').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="BACK END" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
              </DivideModuleCard>
            </div>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '20%', width: '100%', padding: '0 3vw',
            }}
            >
              <ModuleCard
                moduleName="ALGORITHM"
                backgroundImage={pythonAlg}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('pythonAlg').style.display = 'block';
                  document.getElementById('pythonAlg').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="MOBILE APPLICATION"
                backgroundImage={javaMobile}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('javaMobile').style.display = 'block';
                  document.getElementById('javaMobile').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="GAME DEVELOPMENT"
                backgroundImage={javaGame}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('javaGame').style.display = 'block';
                  document.getElementById('javaGame').scrollIntoView({ behavior: 'smooth' });
                }}
              />
            </div>
          </>
        ) : (
          <>
            <ModuleCard
              moduleName="JAVA BASICS"
              backgroundImage={javaBasics}
              onClick={() => {
                hideCarousel();
                document.getElementById('javaBasics').style.display = 'block';
                document.getElementById('javaBasics').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="JAVA ADVANCED"
              backgroundImage={javaAdvanced}
              onClick={() => {
                hideCarousel();
                document.getElementById('javaAdvanced').style.display = 'block';
                document.getElementById('javaAdvanced').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="FRONT END"
              backgroundImage={pythonFE}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonFE').style.display = 'block';
                document.getElementById('pythonFE').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="BACK END"
              backgroundImage={pythonBE}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonBE').style.display = 'block';
                document.getElementById('pythonBE').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="ALGORITHM"
              backgroundImage={pythonAlg}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonAlg').style.display = 'block';
                document.getElementById('pythonAlg').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="MOBILE APPLICATION"
              backgroundImage={javaMobile}
              onClick={() => {
                hideCarousel();
                document.getElementById('javaMobile').style.display = 'block';
                document.getElementById('javaMobile').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="GAME DEVELOPMENT"
              backgroundImage={javaGame}
              onClick={() => {
                hideCarousel();
                document.getElementById('javaGame').style.display = 'block';
                document.getElementById('javaGame').scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </>
        )}
      </Section>

      {/*
      *
      * JAVA BASICS CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="javaBasics"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={javaBasics}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="JAVA BASICS" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Strong foundation in programming" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Introduction & Core Java" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Strings: Working with text" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Arrays & HashMap" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Memory allocation - OOP Basics" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Abstraction & Encapsulation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Inheritance & Polymorphism" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Interface & Abstract Class" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * JAVA ADVANCED CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="javaAdvanced"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={javaAdvanced}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="JAVA ADVANCED" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Java basics + some OOP knowledge + SQL" />
                  <MainSubtitle text="Advanced knowledge in Java" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Files and I/O" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Generic Programming" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Collections" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Exception Handling" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Lambda Expression" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Network Programming" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Multithread Programming" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Regular Expressions" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * FRONT END CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonFE"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonFE}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="FRONT END" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Prototype building, Landing page, dynamic website" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="HTML & CSS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Advanced CSS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Bootstrap" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Javascript" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Advanced JS & Typescript" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="React Basics" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="React Functional Components" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="React Router & Redux" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Styling & Ant Design" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="API Integration" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonPrj}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Build a static web page to display your bio" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%' }}>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Create an interactive application through command line" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * BACK END CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonBE"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonBE}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="BACK END" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Strong backend web services" />
                  <MainSubtitle text="Develop RESTful API" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW COURSE I" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="SQL Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="SQL fundamentals" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="CRUD Tables Operation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="SQL Advanced" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="AWS Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Deploy SQL Server on AWS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="NoSQL & its usage" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW COURSE II" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Microservices & REST API introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Spring Boot" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Exception Handling and Validation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Mockito for Unit Test & Swagger" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Security in REST API" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="AWS In-depth" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Deploy application on AWS using Docker" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * ALGORITHM AND DATA STRUCTURE CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonAlg"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonAlg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="ALGORITHM AND DATA STRUCTURE" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Optimize code" />
                  <MainSubtitle text="Ace the coding interview" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Complexity" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Bubble & Insertion sort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Quicksort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Merge sort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Linear & Binary search" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Hash table" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Stack & Queue" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Linked list" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="BST & traversal" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * JAVA MOBILE DEVELOPMENT CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="javaMobile"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={javaMobile}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="MOBILE DEVELOPMENT" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="From Java to Dart" />
                  <MainSubtitle text="Build a Mobile App on both Android and iOS using Dart" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Introduction and setup" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Dart Basics" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Dart Advanced" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Widgets" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Gestures" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="State Management" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Animation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Introducing API" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Accessing REST API" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Database Concepts" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonPrj}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Build your own mobile app" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%' }}>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Create an interactive application through command line" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * JAVA GAME DEVELOPMENT CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="javaGame"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={javaGame}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="GAME DEVELOPMENT" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Prerequisite:" />
                  <MainSubtitle text="Java basics + Java Advanced" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Libgdx Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Gdx Sampler Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Asset Management" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Scene 2D" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Packing Custom Skin" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Create Custom Skin" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Add Sounds" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />
      <UpButton />
      <Footer />
    </>
  );
};

export default JavaPage;
