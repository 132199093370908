/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'aos/dist/aos.css';
import * as React from 'react';
import * as moduleCardStyles from '../styles/module-card.module.css';
import MainTitle from './MainTitle';

const ModuleCard = ({
  moduleName, backgroundImage, style, onClick,
}) => (
  <>
    <div
      onClick={onClick}
      data-aos="fade-down"
      data-aos-duration="500"
      className={moduleCardStyles.container}
      style={{
        ...style, backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', backgroundSize: 'cover',
      }}
    >
      <MainTitle text={moduleName} className={moduleCardStyles.cardTitleFont} />
      <br />
      <button type="button" className={`${moduleCardStyles.btnContainer} ${moduleCardStyles.btnTitleFont}`}>
        <span>LEARN MORE</span>
      </button>
    </div>
  </>
);

export default ModuleCard;
