/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'aos/dist/aos.css';
import * as React from 'react';
import * as moduleCardStyles from '../styles/divide-module-card.module.css';
import MainTitle from './MainTitle';

const DivideModuleCard = ({
  moduleName, backgroundImage, onClick, children,
}) => (
  <>
    <div onClick={onClick} data-aos="fade-down" data-aos-duration="500" className={`${moduleCardStyles.container}`} style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <MainTitle text={moduleName} className={moduleCardStyles.cardTitleFont} />
      <div className={moduleCardStyles.divide}>
        {children}
      </div>
    </div>
  </>
);

export default DivideModuleCard;
